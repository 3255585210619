.customer {
  height: 90vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 200, 0, 5%);
}
.customer__iphone {
  max-height: 500px;
}
.customer__samsung {
  max-height: 500px;
}
.customer__title {
  font-size: 36px;
  color: black;
  margin-top: 0px;
  margin-bottom: 5px;
}
.customer__text {
  font-size: 40px;
  color: black;
}
.customer__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 40%;
  justify-content: space-between;
}
.customer__subTitle {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 170%;
  color: #ffc800;
}
.customer__desc {
  width: 90%;
  height: auto;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.customer__links-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.customer_phones {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
}

.customer_phones:hover {
  -moz-transform: translate(0px, -12px);
  -ms-transform: translate(0px, -12px);
  -o-transform: translate(0px, -12px);
  -webkit-transform: translate(0px, -12px);
  transform: translate(0px, -12px);
}
.customer__line-through {
  width: 63px;
  height: 3px;
  margin-left: 2%;
  margin-top: 1%;
  align-self: center;
  background-color: #dfdfdf;
}

@media screen and (max-width: 1024px) {
  .customer__samsung {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .customer {
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
    text-align: center;
  }
  .customer__links {
    align-items: center;
    width: 90%;
  }
  .customer__links-images {
    flex-direction: column;
  }
  .customer__iphone {
    max-height: 440px;
  }
  .customer__title {
    font-size: 35px;
  }
  .customer__subTitle {
    justify-content: center;
  }
  .customer__text {
    font-size: 30px;
  }
}
