.post {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 4% 20% 5%;
  align-items: center;
}

.post__details {
  width: 80%;
}

.post__productName {
  display: inline-block;
  padding-bottom: 3px;
  width: fit-content;
  border-bottom: 2px solid #2d88ff;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: rgb(35, 35, 35);
  align-self: flex-start;
}

.post__title {
  font-size: 48px;
  font-weight: 600;
  margin: 0px;
  align-self: flex-start;
}
.post__date {
  color: rgb(102, 102, 102);
  font-size: 14px;
  align-self: flex-start;
}

.post__paragraph {
  width: 80%;
  font-size: 19px;
  line-height: 1.4211;
  font-weight: 400;
  letter-spacing: 0.012em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 5%;
  margin-right: 5%;
}
.post__img1 {
  width: 800px;
  height: auto;
  padding-bottom: 4%;
  padding-top: 4%;
  object-fit: cover;
}

.post__img2 {
  width: 50%;
  height: auto;
  padding-bottom: 4%;
  padding-top: 4%;
  object-fit: cover;
}
.post__imgBk {
  background-color: #f5f5f7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.post__newsRoom {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1068px) {
  .post__paragraph {
    width: 85%;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.022em;
  }
  .post__img1 {
    width: 650px;
    height: 350px;
  }
  .post__img2 {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .post__paragraph {
    width: 90%;
  }
  .post {
    padding: 4% 10% 5%;
  }
}
@media screen and (max-width: 425px) {
  .post {
    padding: 4% 7% 5%;
  }
  .post__img1 {
    width: 100%;
    height: 268.69px;
  }
  .post__img2 {
    height: 350px;
  }
}
