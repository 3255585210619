#form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #ffc800;
}

.form__desc {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form__desc > p {
  width: 70%;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
}

.form__desc > h2 {
  color: black;
  font-size: 30px;
  font-weight: 700;
  font-family: Poppins;
  width: 70%;
}

form {
  width: 573.49px;
  height: 563.34px;
  border-radius: 10px;
  padding: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
}

span {
  padding: 3px;
  color: red;
}

input,
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 13px 15px;
  font-size: 15px;
  resize: none;
  margin-top: 15px;
  background-color: #f2f1f1;
}

input[type='submit'],
.button {
  background: black;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  margin-bottom: 5%;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 10px;
  display: block;
  appearance: none;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}

input[type='submit']:disabled,
input[type='submit'][disabled] {
  background: #ccc;
  color: white;
}

textarea {
  font-family: inherit;
  height: 138.31px;
}

@media screen and (max-width: 768px) {
  textarea {
    margin-bottom: 5px;
  }
  form {
    width: 100%;
  }
  #form {
    height: auto;
    flex-direction: column;
    padding-bottom: 5%;
  }
  .form__desc {
    width: 100%;
    text-align: center;
    align-items: center;
  }
}
