.cardPost {
  display: flex;
  flex-direction: column;
  width: 98%;
}
.cardPost__imgWrapper {
  overflow: hidden;
  height: 40%;
  width: 95%;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
.cardPost__img {
  transition: transform 0.25s ease-out;
  width: auto;
  max-width: 480px;
  height: auto;
}

.cardPost__img:hover {
  transform: scale(1.1);
}

.cardPost__productName {
  color: #444950;
  width: fit-content;
  margin-bottom: 0;
  border-bottom: 2px solid #2d88ff;
}
.cardPost__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  cursor: pointer;
}

.cardPost__borderLine {
  height: 2px;
  width: 100%;
  background: #2d88ff;
  margin-top: 4px;
}
