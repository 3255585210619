.Modal__Login > h3 {
  font-weight: 600;
  text-align: left;
}
a{
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}
.Overlay.Show {
  display: block;
}
.Modal {
  position: fixed;
  left: -150vh;
  background-color: white;
  width: 300px;
  height: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  bottom: 0;
  transition: all 0.3s ease-out;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Modal.Show {
  left: 0;
}
.Modal__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Modal__login-button {
  margin-top: 8%;
  margin-bottom: 5%;
  height: 50px;
  width: 83%;
  border-radius: 20px;
  background-color: #ffc800;
  border: 0;
  font-weight: 500;
  font-size: 19px;
  cursor:pointer;
}
.Modal__apps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8%;
}
.Modal__apps-logo {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.Modal__apps-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

