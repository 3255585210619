.faq {
  display: flex;
  justify-content: center;
}
.faq__container {
  width: 80%;
  padding-top: 1.5%;
  padding-bottom: 5%;
}
.faq__container > h1 {
  font-size: 2rem;
  color: #fdc00b;
  font-weight: 500;
}
