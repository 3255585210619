.terms {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  padding-top: 1%;
}

.terms > h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
.terms > p {
  color: #243a52;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 1%;
  margin-top: 0;
}
.terms > p > span {
  color: black;
  font-weight: 500;
  padding: 0;
}
