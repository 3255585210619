.featuredNews {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0% 5% 5% 7%;
}

.featuredNews__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2%;
  align-items: center;
}
.featuredNews__link {
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  padding-top: 1%;
}
.featuredNews__link > p {
  font-size: 1rem;
  color: #505E6B;
  text-align: center;
  font-weight: 500;
    padding-left: 1%;
}

@media screen and (max-width: 1068px) {
  .featuredNews__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 425px) {
  .featuredNews__container {
    grid-template-columns: 1fr;
  }
}
