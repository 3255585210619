.navbar__menu {
  display: flex;
  padding-left: 5%;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  min-width: 230px;
}
.navbar__login {
  margin-right: 3%;
  height: 44px;
  width: 108px;
  border-radius: 8px;
  background-color: white;
  border: 0;
  font-weight: 500;
  font-weight: 00;
  font-size: 18px;
  line-height: 150%;
  font-family: Poppins;
}

.navbar__links {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: space-between;
  padding-right: 3%;
}

.navbar__links > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}

@media screen and (max-width: 1024px) {
  .navbar__links {
    width: 35%;
  }
}
@media screen and (max-width: 768px) {
  .navbar__links {
    width: 45%;
  }
}
@media screen and (max-width: 425px) {
  .navbar__login {
    display: none;
  }
  .navbar__links {
    display: none;
  }
}
