.footer {
  height: 116px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f7;
}

.footer > a {
  font-size: 14px;
  font-weight: 500;
}

.footer > img {
  height: 42px;
  width: auto;
}

.footer__links {
  width: 50%;
  text-align: right;
}
.footer__links > a {
  padding-left: 6%;
}

.footer__socialLinks {
  width: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer__socialLinks > a {
  display: contents;
}

@media screen and (max-width: 426px) {
  .footer > img {
    grid-area: logo;
  }
  .footer__socialLinks {
    grid-area: socialLinks;
    display: flex;
    flex-direction: row;
    width: 60%;
  }
  .footer__links {
    grid-area: links;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .footer {
    height: 200px;
    padding-left: 5.5%;
    padding-right: 2.5%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: grid;
    grid-template-areas:
        'logo links'
        'socialLinks links';
    grid-gap: 10px;
    background-color: #2196f3;
    background: #f5f5f7;
    align-items: center;
}
}
