.collapsible {
  padding: 1%;
  padding-left: 0;
}
.collapsible__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(64, 58, 58, 0.05);
  cursor: pointer;
}
.collapsible__label > h1 {
  font-size: 1.35rem;
  font-weight: 500;
}

.collapsible__content {
  border-bottom: 0.5px solid rgba(64, 58, 58, 0.05);
  overflow: hidden;
  transition: height ease 0.3s;
}

.collapsible__contentContainer{
    padding-top: 3%;
    padding-bottom:3%;
}
