.whyChooseUs {
  height: auto;
  padding-bottom: 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.whyChooseUs > h1 {
  padding: 3%;
}

.whyChooseUs__container {
  height: 80%;
  width: 90%;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 270px 270px;
  row-gap: 5%;
  justify-content: center;
  padding-bottom: 8%;
}

.whyChooseUs__box {
  height: 100%;
  width: 90%;
  border: none;
  -webkit-box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: center;
  padding: 1%;
  text-align: center;
  background-color: white;
}
.whyChooseUs__box > h1 {
  height: 20%;
  text-align:center;
}
.whyChooseUs__box > ul {
  height: 50%;
  margin: 6%;
  list-style: outside none square;
  padding-left: 20px;
}
.whyChooseUs__box > ul > li {
  padding-bottom: 7%;
  font-size: 15px;
}

.whyChooseUs__box:hover {
  background-color: rgb(253, 192, 11);
}

@media screen and (max-width: 768px) {

  .whyChooseUs__container {
    height: 90%;
    width: 100%;
    display: grid;
    grid-template-columns: 60%;
    grid-template-rows: 250px  250px  250px  250px  250px  250px;
    grid-row-gap: 5%;
    row-gap: 2%;
    padding-bottom: 30%;
  }
}

@media screen and (max-width: 425px) {
  .whyChooseUs__container {
    grid-template-columns: 80%;
    padding-bottom: 65%;
  }
}
