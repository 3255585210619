.newsroom {
  padding-top:3%;
  padding-bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsroom__container {
  width: 40%;
}
.newsroom__container > h1 {
    font-size: 2rem;
    color: #666666;
    text-align: left;
    padding-bottom: 5%;
  }
@media screen and (max-width: 1068px) {
    .newsroom__container {
        width: 50%;
    }
}

@media screen and (max-width: 425px) {
    .newsroom__container {
        width: 70%;
    }
}