.carousel {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.carousel__prevArrow {
  z-index: 999;
  height: 230px;
  width: 13%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel__nextArrow {
  z-index: 999;
  height: 230px;
  width: 13%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel__nextArrow .carousel__prevArrow {
  display: none;
}

@media screen and (max-width: 768px) {
  .carousel__nextArrow .carousel__prevArrow {
    display: block;
  }
}
