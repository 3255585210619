.partnerships {
    display: flex;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
  }
  
  .partnerships__container {
    width: 80%;
  }
  
  .partnerships__container > h1 {
    font-size: 2rem;
    color: #fdc00b;
    font-weight: 500;
  }
  
  .partnerships__item {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
    border: 1px solid transparent;
    padding: 1rem;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .partnerships__item:hover {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
  }
  
  .partnerships__content {
    flex: 1;
  }
  
  .partnerships__item > h2 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 0;
  }
  
  .partnerships__item > p {
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
  }
  
  .partnerships__image {
    max-width: 150px;
    height: auto;
    margin-left: 20px;
    border-radius: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .partnerships__item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .partnerships__image {
      margin-left: 0;
      margin-top: 10px;
      max-width: 100%;
    }
  }
  