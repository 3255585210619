.banner {
  height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  font-size: 46px;
  line-height: 142%;
}
.banner__desc {
  width: 35%;
  padding-right: 2%;
  padding-left: 4%;
  padding-bottom: 4%;
  padding-top: 4%;
}

.banner__image {
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5%;
}
.banner__image > img {
  height: auto;
  width: 100%;
}
.banner__desc > button {
  width: 209px;
  height: 55px;
  background: #fdc00b;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.banner__desc > p {
  font-size: 16px;
  line-height: 24px;
  width: 90%;
  padding-bottom: 5%;
}

@media screen and (max-width: 768px) {
  .banner {
     height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align:center;
   
  }
  .banner__desc {
    width: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .banner__image {
    width: 80%;
    padding-bottom: 9%;
    padding-top: 4%;
}

}
