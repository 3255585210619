
.cookie {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.55);
  }
  
  .cookie__grid {
    background-color: white;
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: 10%;
    margin-bottom: 1%;
    border-radius: 10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .cookie p {
    display: inline;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .cookie a {
    color: #0066cc;
  }
  .cookie button {
    background: #ffc800;
    border: none;
    align-items: center;
    border-radius: 50px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: 155px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    margin-left: 25px;
    padding: 0 10px;
    transition: background-color 0.25s linear;
  }
  
  @media screen and (max-width: 425px) {
    .cookie__grid {
      flex-direction: column;
      max-height: 20%;
      padding-bottom: 2%;
    }
    .cookie p {
      text-align: center;
    }
    .cookie button {
      margin-left: 0;
    }
  }
  