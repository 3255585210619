.privacyPolicy {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;

  padding-bottom: 5%;
  padding-top: 1%;
}
.privacyPolicy > h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
.privacyPolicy > p {
  color: #243a52;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 1%;
  margin-top: 0;
}
.privacyPolicy > p > span {
  color: black;
  font-weight: 500;
  padding: 0;
}
.privacyPolicy > ul > li > span {
  color: black;
  font-weight: 500;
  padding: 0;
}

.privacyPolicy > table, th, td {
    text-align: left;
    border: 1px solid black;
  }
