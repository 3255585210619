.css-1fzpoyk {
  opacity: 1;
}
.container {
  padding: 2.5rem 0;
  text-align: center;
  height: 80vh;
  background-color: green;
}

.slide {
  transform: scale(0.7);
  transition: transform 300ms;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.prevArrow {
  position: absolute;
  top: 40%;
  left: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}

.nextArrow {
  position: absolute;
  top: 40%;
  right: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}
.img {
  height: 300px;
  width: auto;
  border-width: 1px;
  border-color: #e9e6e6;
  border-style: solid;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.08));
  border-radius: 20px;
}

.backOffice {
  height: auto;
  
  padding-top: 1%;
  padding-bottom:9%;
  display: flex;
  flex-direction: column;
padding-bottom: 7%;
  align-items: center;
  background-color: rgba(64, 58, 58, 0.05);
}
.backOffice__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
}
.backOffice__title > h3 {
  color: #ffc800;
  font-size: 28px;
  margin-bottom: 0;
}
.backOffice__title > h1 {
  font-size: 36px;
  width: 50%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 3%;
}

.backOffice__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.css-1qzevvg {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 30px;
  margin: 0 auto 0 !important;
  width: 30% !important;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .img {
    height: 240px;
  }
  .backOffice{
    padding-bottom: 8%;

  }

  .backOffice__title > h1 {
    font-size: 28px;
    width: 70%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0%;
  }
}

@media screen and (max-width: 768px) {
  .backOffice {
    padding-bottom: 11%;
  }
  .backOffice__slide {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 425px) {
  .img {
    height: 190px;
  }

  .css-1qzevvg {
    margin-top: 5% !important;
  }
}
