#ac_form {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
  min-height: 100vh;
}

.ac_form__desc {
  width: 100%;
  height: auto;
}

.ac_form__desc > p {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
}

.ac_form__desc > p a {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color: blue !important;
}

.ac_form__desc > h2 {
  color: black;
  font-size: 30px;
  font-weight: 700;
  font-family: Poppins;
}

.ac_form__submit {
  width: 600px;
  border-radius: 10px;
  padding: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.ac_form__submit span {
  padding: 3px;
  color: red;
}

.ac_form__submit input,
.ac_form__submit textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 13px 15px;
  font-size: 15px;
  resize: none;
  margin-top: 5px;
  background-color: #fff;
}

.ac_form__submit input[type='submit'],
.ac_form__submit button {
  background: #D54C4C;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 10px;
  display: block;
  appearance: none;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}

.ac_form__submit input[type='submit']:disabled,
.ac_form__submit input[type='submit'][disabled] {
  background: #ccc;
  color: white;
}

.ac_form__submit .radio-group {
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  display: grid;
  line-height: 1.5;
  grid-template-columns: 2em auto;
  gap: 0.5em;
}

.ac_form__submit .radio-group + .ac_form__submit .radio-group {
  margin-top: 1em;
}

.ac_form__submit input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.ac_form__submit input[type='radio']::before {
  content: '';
  content: '';
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
}

.ac_form__submit input[type='radio']:checked::before {
  transform: scale(1);
}

.ac_form__submit input[type='radio']:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  #ac_form {
    height: auto;
    flex-direction: column;
    padding-bottom: 5%;
  }
  .ac_form__desc {
    width: 100%;
  }
  .ac_form__submit {
    width: 100%;
  }
}
