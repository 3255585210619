.bruschi {
  width: 100%;
  text-align: center;
}

.bruschi__banner {
  width: 60%;
  height: 50%; /* Adjust the max height as needed */
  object-fit: cover;
  margin-top: 40px;
}
.bruschi__content {
  padding: 2% 10%;
}

.bruschi__testimonial-section {
  background-color: #fef8e7; /* Light cream background */
  padding: 2rem;
  margin-top: 2rem;
}

.bruschi__testimonial-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fdc00b;
}

.bruschi__testimonial {
  font-size: 1.5rem; /* Larger font size */
  margin-top: 1.5rem;
  font-style: italic;
  color: #333;
}

.bruschi__author {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #333;
}

.bruschi__products-title {
  font-size: 2.5rem; /* Larger font size */
  color: #fdc00b; /* DrinKing yellow color */
  margin-top: 2rem;
}

.bruschi__products {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.bruschi__product {
  flex: 1;
  padding: 1rem;
  max-width: 300px; /* Limit the width of each product */
}

.product__image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.bruschi__product p {
  font-size: 1.5rem; /* Larger font size */
  font-weight: 500;
  color: #fdc00b;
}

.bruschi__product ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  text-align: left;
}

.bruschi__product ul li {
  font-size: 1.25rem; /* Larger font size */
  color: #666;
}

.bruschi__fiscal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 80%; /* Increase width */
  margin-left: auto;
  margin-right: auto;
}

.bruschi__fiscal img {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
}

.bruschi__fiscal p {
  font-size: 1.5rem; /* Larger font size */
  color: #666;
  text-align: left;
  width: 100%;
  max-width: 800px;
}

@media (max-width: 768px) {
  .bruschi__products {
    flex-direction: column;
    align-items: center;
  }

  .bruschi__fiscal p {
    text-align: center;
  }
}
