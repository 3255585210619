.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.carousel__mid {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__child {
  position: absolute;
  transition: transform 0.2s;
  pointer-events: none;
}

.carousel__child--active {
  pointer-events: all;
}
.carousel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel__button {
  position: relative;
  padding: 21px;
  border: none;
  overflow: hidden;
  outline: 0;
  line-height: 0;
  border-radius: 60px;
  background-color: #fdc00b;
}
.carousel__img {
  width: 99%;
  height: 285px;
  border-radius: 20px;
  object-fit: contain;
  padding-top: 2%;
}

@media screen and (max-width: 426px) {
  .mobile-img {
    height: 195px;
    width: 100%;
  }
  .carousel__img {
    width: 95%;
  }
}
