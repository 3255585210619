.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  
  .navbar {
    position: sticky;
    top: 0;
    height: 73.52px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FDC00B;
    z-index: 9999;
  }
  
  a,button, input, textarea{
    outline: none !important;
  }
  