.unsubscribe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .unsubscribe h1 {
    font-size: 2rem;
    color: #333;
  }
  