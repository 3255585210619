.bartender {
  height: 90vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bartender__devices {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
}

.bartender__devices:hover {
  -moz-transform: translate(0px, -12px);
  -ms-transform: translate(0px, -12px);
  -o-transform: translate(0px, -12px);
  -webkit-transform: translate(0px, -12px);
  transform: translate(0px, -12px);
}

.bartender__iphone {
  max-height: 500px;
}
.bartender__samsung {
  max-height: 500px;
}

.bartender__title {
  font-size: 36px;
  color: black;
  margin-top: 0px;
  margin-bottom: 5px;
}
.bartender__text {
  font-size: 40px;
  color: black;
}
.bartender__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  justify-content: space-around;
  height: 100%;
  padding: 20px;
}
.bartender__subTitle {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 170%;
  color: #ffc800;
}
.bartender__desc {
  width: 100%;
  height: 120px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.bartender__links-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bartender__line-through {
  width: 63px;
  height: 3px;
  margin-left: 2%;
  margin-top: 1%;
  align-self: center;
  background-color: #dfdfdf;
}

@media screen and (max-width: 1024px) {
  .bartender__samsung {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .bartender {
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .bartender__links {
    align-items: center;
    width: auto;
  }
  .bartender__links-images {
    flex-direction: column;
  }
  .bartender__iphone {
    max-height: 440px;
  }
  .bartender__desc {
    width: 90%;
    height: auto;
  }
  .bartender__title {
    font-size: 35px;
  }
  .bartender__subTitle {
    justify-content: center;
  }
  .bartender__text {
    font-size: 30px;
  }
}
